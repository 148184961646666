import React from 'react';

export default function FourHobs({ color }) {
  return(
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>four-hobs</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Hob-Count" transform="translate(-147.000000, -406.000000)" stroke={color}>
          <g id="4-hobs" transform="translate(20.000000, 394.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="four-hobs" transform="translate(128.000000, 0.000000)">
                <rect id="Rectangle" strokeWidth="2" x="0" y="0" width="24" height="24" rx="1"></rect>
                <g id="big-hob" transform="translate(2.000000, 2.333333)">
                  <line x1="0.625" y1="4.6875" x2="0.625" y2="5.3125" id="Line-2-Copy-2" strokeLinecap="square" transform="translate(0.625000, 5.000000) rotate(-90.000000) translate(-0.625000, -5.000000) "></line>
                  <line x1="9.375" y1="4.6875" x2="9.375" y2="5.3125" id="Line-2-Copy-3" strokeLinecap="square" transform="translate(9.375000, 5.000000) rotate(-90.000000) translate(-9.375000, -5.000000) "></line>
                  <line x1="5" y1="0.625" x2="5" y2="1.25" id="Line-2" strokeLinecap="square"></line>
                  <line x1="5" y1="8.75" x2="5" y2="9.375" id="Line-2-Copy" strokeLinecap="square"></line>
                  <circle id="Oval" cx="5" cy="5" r="5"></circle>
                </g>
                <g id="small-hob" transform="translate(14.333333, 15.000000)">
                  <line x1="3.5" y1="5.25" x2="3.5" y2="5.75" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="1" x2="3.5" y2="1.5" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="5.75" y1="3.25" x2="5.75" y2="3.75" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(5.750000, 3.500000) rotate(-90.000000) translate(-5.750000, -3.500000) "></line>
                  <line x1="1.375" y1="3.375" x2="1.375" y2="3.625" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(1.375000, 3.500000) rotate(-90.000000) translate(-1.375000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" cx="3.56666667" cy="3.36666667" r="2.5"></circle>
                </g>
                <g id="medium-hob" transform="translate(14.500000, 3.900000)">
                  <line x1="3.5" y1="5.95" x2="3.5" y2="6.65" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="0" x2="3.5" y2="0.7" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="6.65" y1="3.15" x2="6.65" y2="3.85" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(6.650000, 3.500000) rotate(-90.000000) translate(-6.650000, -3.500000) "></line>
                  <line x1="0.525" y1="3.325" x2="0.525" y2="3.675" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(0.525000, 3.500000) rotate(-90.000000) translate(-0.525000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" cx="3.5" cy="3.5" r="3.5"></circle>
                </g>
                <g id="medium-hob-copy" transform="translate(3.500000, 14.900000)">
                  <line x1="3.5" y1="5.95" x2="3.5" y2="6.65" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="0" x2="3.5" y2="0.7" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="6.65" y1="3.15" x2="6.65" y2="3.85" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(6.650000, 3.500000) rotate(-90.000000) translate(-6.650000, -3.500000) "></line>
                  <line x1="0.525" y1="3.325" x2="0.525" y2="3.675" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(0.525000, 3.500000) rotate(-90.000000) translate(-0.525000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" cx="3.5" cy="3.5" r="3.5"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
