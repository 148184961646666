import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import HobCount from '../../components/GetQuoteSteps/hob-count';

export default function QuoteHobCountPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <HobCount set={quote.set} hobCount={quote.hobCount} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
