import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import TwoHobs from '../Icons/two-hobs';
import ThreeHobs from '../Icons/three-hobs';
import FourHobs from '../Icons/four-hobs';
import FiveHobs from '../Icons/five-hobs';
import CheckBox from '../Form/checkbox';

export default function HobCount({ set, hobCount }) {
  const intl = useIntl();
  const nextStepPath = '/get-quote/oven';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteHobCountHeadline" })}</FormHeadline>

      <Link to={nextStepPath}>
        <CheckBox checked={hobCount === 2} handleClick={() => set({hobCount: 2})}>
          <TwoHobs color={'#333'} />
          <div>{intl.formatMessage({ id: "quoteHobCountOptionOne" })}</div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={hobCount === 3} handleClick={() => set({hobCount: 3})}>
          <ThreeHobs color={'#333'} />
          <div>{intl.formatMessage({ id: "quoteHobCountOptionTwo" })}</div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={hobCount === 4} handleClick={() => set({hobCount: 4})}>
          <FourHobs color={'#333'} />
          <div>{intl.formatMessage({ id: "quoteHobCountOptionThree" })}</div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={hobCount === 5} handleClick={() => set({hobCount: 5})}>
          <FiveHobs color={'#333'} />
          <div>{intl.formatMessage({ id: "quoteHobCountOptionFour" })}</div>
        </CheckBox>
      </Link>
    </FormContainer>
  );
}
