import React from 'react';

export default function FiveHobs({ color }) {
  return(
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>five-hobs</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Hob-Count" transform="translate(-147.000000, -508.000000)" stroke={color}>
          <g id="5-hobs" transform="translate(20.000000, 496.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="five-hobs" transform="translate(128.000000, 0.000000)">
                <rect id="Rectangle" strokeWidth="2" x="0" y="0" width="24" height="24" rx="1"></rect>
                <g id="big-hob" transform="translate(8.000000, 2.333333)">
                  <line x1="0.5" y1="3.75" x2="0.5" y2="4.25" id="Line-2-Copy-2" strokeLinecap="square" transform="translate(0.500000, 4.000000) rotate(-90.000000) translate(-0.500000, -4.000000) "></line>
                  <line x1="7.5" y1="3.75" x2="7.5" y2="4.25" id="Line-2-Copy-3" strokeLinecap="square" transform="translate(7.500000, 4.000000) rotate(-90.000000) translate(-7.500000, -4.000000) "></line>
                  <line x1="4" y1="0.5" x2="4" y2="1" id="Line-2" strokeLinecap="square"></line>
                  <line x1="4" y1="7" x2="4" y2="7.5" id="Line-2-Copy" strokeLinecap="square"></line>
                  <circle id="Oval" cx="4" cy="4" r="4"></circle>
                </g>
                <g id="small-hob" transform="translate(1.333333, 8.000000)">
                  <line x1="3.5" y1="5.2" x2="3.5" y2="5.7" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="1.1" x2="3.5" y2="1.5" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="5.66666667" y1="3.25" x2="5.66666667" y2="3.75" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(5.666667, 3.500000) rotate(-90.000000) translate(-5.666667, -3.500000) "></line>
                  <line x1="1.375" y1="3.375" x2="1.375" y2="3.625" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(1.375000, 3.500000) rotate(-90.000000) translate(-1.375000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" strokeWidth="0.8" cx="3.56666667" cy="3.36666667" r="2.5"></circle>
                </g>
                <g id="small-hob-copy" transform="translate(4.333333, 15.000000)">
                  <line x1="3.5" y1="5.2" x2="3.5" y2="5.7" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="1.1" x2="3.5" y2="1.5" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="5.66666667" y1="3.25" x2="5.66666667" y2="3.75" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(5.666667, 3.500000) rotate(-90.000000) translate(-5.666667, -3.500000) "></line>
                  <line x1="1.375" y1="3.375" x2="1.375" y2="3.625" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(1.375000, 3.500000) rotate(-90.000000) translate(-1.375000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" strokeWidth="0.8" cx="3.56666667" cy="3.36666667" r="2.5"></circle>
                </g>
                <g id="small-hob-copy-2" transform="translate(12.333333, 15.000000)">
                  <line x1="3.5" y1="5.2" x2="3.5" y2="5.7" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="1.1" x2="3.5" y2="1.5" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="5.66666667" y1="3.25" x2="5.66666667" y2="3.75" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(5.666667, 3.500000) rotate(-90.000000) translate(-5.666667, -3.500000) "></line>
                  <line x1="1.375" y1="3.375" x2="1.375" y2="3.625" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(1.375000, 3.500000) rotate(-90.000000) translate(-1.375000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" strokeWidth="0.8" cx="3.56666667" cy="3.36666667" r="2.5"></circle>
                </g>
                <g id="small-hob-copy-3" transform="translate(15.333333, 8.000000)">
                  <line x1="3.5" y1="5.2" x2="3.5" y2="5.7" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.5" y1="1.1" x2="3.5" y2="1.5" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="5.66666667" y1="3.25" x2="5.66666667" y2="3.75" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(5.666667, 3.500000) rotate(-90.000000) translate(-5.666667, -3.500000) "></line>
                  <line x1="1.375" y1="3.375" x2="1.375" y2="3.625" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(1.375000, 3.500000) rotate(-90.000000) translate(-1.375000, -3.500000) "></line>
                  <circle id="Oval-Copy-9" strokeWidth="0.8" cx="3.56666667" cy="3.36666667" r="2.5"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
