import React from 'react';

export default function ThreeHobs({ color }) {
  return(
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>three-hobs</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Hob-Count" transform="translate(-147.000000, -304.000000)" stroke={color}>
          <g id="3-hobs" transform="translate(20.000000, 292.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="three-hobs" transform="translate(128.000000, 0.000000)">
                <rect id="Rectangle" strokeWidth="2" x="0" y="0" width="24" height="24" rx="1"></rect>
                <g id="big-hob" transform="translate(2.000000, 6.333333)">
                  <line x1="0.666666667" y1="5" x2="0.666666667" y2="5.66666667" id="Line-2-Copy-2" strokeLinecap="square" transform="translate(0.666667, 5.333333) rotate(-90.000000) translate(-0.666667, -5.333333) "></line>
                  <line x1="10" y1="5" x2="10" y2="5.66666667" id="Line-2-Copy-3" strokeLinecap="square" transform="translate(10.000000, 5.333333) rotate(-90.000000) translate(-10.000000, -5.333333) "></line>
                  <line x1="5.33333333" y1="0.666666667" x2="5.33333333" y2="1.33333333" id="Line-2" strokeLinecap="square"></line>
                  <line x1="5.33333333" y1="9.33333333" x2="5.33333333" y2="10" id="Line-2-Copy" strokeLinecap="square"></line>
                  <circle id="Oval" cx="5.33333333" cy="5.33333333" r="5.33333333"></circle>
                </g>
                <g id="medium-hob" transform="translate(14.000000, 3.333333)">
                  <line x1="4" y1="6.8" x2="4" y2="7.6" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="4" y1="0" x2="4" y2="0.8" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="7.6" y1="3.6" x2="7.6" y2="4.4" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(7.600000, 4.000000) rotate(-90.000000) translate(-7.600000, -4.000000) "></line>
                  <line x1="0.6" y1="3.8" x2="0.6" y2="4.2" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(0.600000, 4.000000) rotate(-90.000000) translate(-0.600000, -4.000000) "></line>
                  <circle id="Oval-Copy-9" cx="4" cy="4" r="4"></circle>
                </g>
                <g id="small-hob" transform="translate(14.333333, 13.333333)">
                  <line x1="3.33333333" y1="5.66666667" x2="3.33333333" y2="6.33333333" id="Line-2-Copy-6" strokeLinecap="square"></line>
                  <line x1="3.33333333" y1="0" x2="3.33333333" y2="0.666666667" id="Line-2-Copy-7" strokeLinecap="square"></line>
                  <line x1="6.33333333" y1="3" x2="6.33333333" y2="3.66666667" id="Line-2-Copy-4" strokeLinecap="square" transform="translate(6.333333, 3.333333) rotate(-90.000000) translate(-6.333333, -3.333333) "></line>
                  <line x1="0.5" y1="3.16666667" x2="0.5" y2="3.5" id="Line-2-Copy-5" strokeLinecap="square" transform="translate(0.500000, 3.333333) rotate(-90.000000) translate(-0.500000, -3.333333) "></line>
                  <circle id="Oval-Copy-9" cx="3.33333333" cy="3.33333333" r="3.33333333"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
